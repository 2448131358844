import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { UtilsString } from '@/utils/utils-string';
import { ssmLoadingService } from './loading-service';
import { ssmMessageService } from './message-service';
import { ssmTokenService } from './token-service';
import router from '@/router/router';
import { RouterNames } from '@/router/routernames';
import { ApiCodesResponse } from './ApiCodesResponse';
import i18n from '@/i18n/i18n';

export default class HttpService {

    private axiosInstance: AxiosInstance = this.initAxiosInstance();

    public initAxiosInstance(): AxiosInstance {
        // Creamos la instancia de axios
        return axios.create({
            headers: this.composeHeaders(),
        });
    }

    public composeHeaders() {
        if (ssmTokenService.hasToken()) {
            return { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + ssmTokenService.getToken(), 'Access-Control-Allow-Origin': 'http://localhost:8080/' };
        } else {
            return { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': 'http://localhost:8080/', 'Access-Control-Allow-Credentials': 'true' };
        }
    }

    public updateTokenHeader() {
        this.axiosInstance.defaults.headers = this.composeHeaders();
    }

    // Método para hacer una petición 'GET' al API
    //   - url: la url completa
    //   - params: parámetros
    //   - fullResponse: por defecto se devuelve solo el 'data' resultado de la petición,
    //                   pero se puede indicar false para obtener la Response entera
    //   - showMessageError: muestra un toast con el error
    public get(url: string, params?: any, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.get(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }

    // Método para hacer una petición 'POST' al API
    // (ver la explicación de los parámetros en el método 'get')
    public post(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.post(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }
    // Método para hacer una petición 'POST' al API
    // (ver la explicación de los parámetros en el método 'get')
    public post_otro(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.post(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse));
    }

    // Método para hacer una petición 'PUT' al API
    // (ver la explicación de los parámetros en el método 'get')
    public put(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.put(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }

    // Método para hacer una petición 'DELETE' al API
    // (ver la explicación de los parámetros en el método 'get')
    public delete(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.delete(url, params);
    }

    // Método para hacer una petición 'PATCH' al API
    // (ver la explicación de los parámetros en el método 'get')
    public patch(url: string, params: any, showLoading: boolean = true, fullResponse: boolean = false,
        showMessageError: boolean = false) {
        this.enableLoading(showLoading);

        return this.axiosInstance.patch(url, params)
            .then((res) => this.processResponse(res, showLoading, fullResponse))
            .catch((res) => this.processError(res, showLoading, showMessageError));
    }

    // Método para recuperar la instancia de axios
    // usado por jest en testing
    public getAxiosInstance(): AxiosInstance {
        return this.axiosInstance;
    }

    private processResponse(response: AxiosResponse, showLoading: boolean, fullResponse: boolean = false) {
        if (showLoading) {
            ssmLoadingService.disableLoading();
        }

        this.tiposDeError(response.status);
        return fullResponse ? response : response.data;
    }

    private processError(error: any, showLoading: boolean, showMessageError: boolean = false) {
        if (showLoading) {
            ssmLoadingService.disableLoading();
        }
        this.tiposDeError(error.response.status);
        let message = '';

        if (showMessageError) {
            if (error.response !== undefined) {
                if (error.response.data !== undefined) {
                    message = UtilsString.ValueOf(error.response.data.message);
                }
            }
            if (UtilsString.IsNullOrWhiteSpace(message)) {
                message = error.message || error.response.statusText;
            }
        }

        if (!UtilsString.IsNullOrWhiteSpace(message) && showMessageError) {
            ssmMessageService.toast(message, ssmMessageService.TypeError);
        }

        throw error;
    }

    private enableLoading(showLoading: boolean) {
        if (showLoading) {
            ssmLoadingService.enableLoading();
        }
    }

    private tiposDeError(responseStatus: number) {

        try {

            switch (responseStatus) {
                case ApiCodesResponse.UNAUTHORIZED: {
                    ssmTokenService.clearToken();
                    router.push({ name: RouterNames.Login });
                    ssmMessageService.toast(i18n.tc('api.UNAUTHORIZED'), ssmMessageService.TypeWarning);
                    break;
                }
                case ApiCodesResponse.NO_CONTENT: {
                    // ssmMessageService.toast(i18n.tc('api.NO_CONTENT'), ssmMessageService.TypeWarning);
                    break;
                }
                case ApiCodesResponse.BAD_REQUEST: {
                    // ssmMessageService.toast(i18n.tc('api.BAD_REQUEST'), ssmMessageService.TypeInfo);
                    break;
                }
                case ApiCodesResponse.FORBIDDEN: {
                    // ssmMessageService.toast(i18n.tc('api.FORBIDDEN'), ssmMessageService.TypeInfo);
                    break;
                }
                case ApiCodesResponse.NOT_FOUND: {
                    // ssmMessageService.toast(i18n.tc('api.NOT_FOUND'), ssmMessageService.TypeInfo);
                    break;
                }
                case ApiCodesResponse.METHOD_NOT_ALLOWED: {
                    // ssmMessageService.toast(i18n.tc('api.METHOD_NOT_ALLOWED'), ssmMessageService.TypeError);
                    break;
                }
                case ApiCodesResponse.NOT_ACCEPTABLE: {
                    // ssmMessageService.toast(i18n.tc('api.NOT_ACCEPTABLE'), ssmMessageService.TypeWarning);
                    break;
                }
                case ApiCodesResponse.CONFLICT: {
                    // ssmMessageService.toast(i18n.tc('api.CONFLICT'), ssmMessageService.TypeError);
                    break;
                }
                case ApiCodesResponse.UNSUPPORTED_MEDIA_TYPE: {
                    // ssmMessageService.toast(i18n.tc('api.UNSUPPORTED_MEDIA_TYPE'), ssmMessageService.TypeError);
                    break;
                }
                case ApiCodesResponse.INTERNAL_SERVER_ERROR: {
                    // ssmMessageService.toast(i18n.tc('api.INTERNAL_SERVER_ERROR'), ssmMessageService.TypeError);
                    break;
                }
            }
        } catch (error) {

        }
    }

}

export const ssmHttpService = new HttpService();
