//Codigos de respuesta que nos puede mandar la API
// más info --> https://www.ibm.com/support/knowledgecenter/es/SSQP76_8.5.1/com.ibm.odm.dserver.events.ref/topics/ref_dse_restapi_responsecodes_errormsgs.html
export class ApiCodesResponse {
    /**Solicitud aceptada; la respuesta contiene el resultado. Este es un código de respuesta general a cualquier solicitud. En las solicitudes GET, el recurso o datos solicitados están en el cuerpo de la respuesta. En las solicitudes PUT o DELETE, la solicitud fue satisfactoria y la información acerca del resultado (como los identificadores de recursos nuevo o los cambios en el estado del recurso) se puede encontrar en el cuerpo de la respuesta.*/
    public static OK: number = 200;

    /**Las operaciones PUT o POST devuelven este código de respuesta e indica que se ha creado un recurso de forma satisfactoria. El cuerpo de la respuesta podría, por ejemplo, contener información acerca de un nuevo recurso o información de validación (por ejemplo, cuándo se actualiza un activo).    */
    public static CREATED: number = 201;

    /**Indica que se ha aceptado la solicitud, pero no había datos para devolver. Este respuesta se devuelve cuando se ha procesado la solicitud, pero no se ha devuelto ninguna información adicional acerca de los resultados.    */
    public static NO_CONTENT: number = 204;

    /**La solicitud no fue válida. Este código se devuelve cuando el servidor ha intentado procesar la solicitud, pero algún aspecto de la solicitud no es válido; por ejemplo, un recurso formateado de forma incorrecta o un intento de despliegue de un proyecto de sucesos no válido en el tiempo de ejecución de sucesos. La información acerca de la solicitud se proporciona en el cuerpo de la respuesta e incluye un código de error y un mensaje de error.     */
    public static BAD_REQUEST: number = 400;

    /**El servidor de aplicaciones devuelve este código de respuesta cuando está habilitada la seguridad y faltaba la información de autorización en la solicitud.*/
    public static UNAUTHORIZED: number = 401;

    /**Indica que el cliente ha intentado acceder a un recurso al que no tiene acceso. Podría producirse si el usuario que accede al recurso remoto no tiene privilegios suficientes; por ejemplo, con el rol WBERestApiUsers o WBERestApiPrivilegedUsers. Los usuarios que intenten acceder a proyectos de sucesos privados que son propiedad de otros podrían recibir también este error, pero solo si tienen el rol WBERestApiUsers en lugar de WBERestApiPrivilegedUsers.    */
    public static FORBIDDEN: number = 403;

    /**Indica que el recurso de destino no existe. Esto podría deberse a que el URI no está bien formado o a que se ha suprimido el recurso.    */
    public static NOT_FOUND: number = 404;

    /**Se produce cuando el recurso de destino no admite el método HTTP solicitado; por ejemplo, el recurso de funciones solo permite operaciones GET.    */
    public static METHOD_NOT_ALLOWED: number = 405;

    /**El recurso de destino no admite el formato de datos solicitado en la cabecera de Accept o el parámetro accept. Es decir, el cliente ha solicitado la devolución de los datos en un determinado formato, pero el servidor no puede devolver datos en ese formato.    */
    public static NOT_ACCEPTABLE: number = 406;

    /**Indica que se ha detectado un cambio conflictivo durante un intento de modificación de un recurso. El cuerpo de la respuesta contiene más información.     */
    public static CONFLICT: number = 409;

    /**El recurso de destino no admite el formato de datos del cuerpo de la solicitud especificado en la cabecera de Content-Type.    */
    public static UNSUPPORTED_MEDIA_TYPE: number = 415;

    /**Se ha producido un error interno en el servidor. Esto podría indicar un problema con la solicitud o un problema en el código del lado del servidor. Se puede encontrar información acerca del error en el cuerpo de respuesta.    */
    public static INTERNAL_SERVER_ERROR: number = 500;

}